$icon-transition-time: 1.0s;
$twitter-color: #00aced;
$github-color: #343434;
$gitea-color: #343436;
$linkedin-color: #0073a4;

@mixin social-media-icon($color, $transition){
  -webkit-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
  &:hover{
    color: $color;
  }
}

.fa-twitter {
  padding: 0px;
  @include social-media-icon($twitter-color, $icon-transition-time);
}

.fa-github {
  padding: 0px;
  @include social-media-icon($github-color, $icon-transition-time);
}

.fa-gitea {
  padding: 0px;
  @include social-media-icon($gitea-color, $icon-transition-time);
}

.fa-linkedin {
  padding: 0px;
  @include social-media-icon($linkedin-color, $icon-transition-time);
}
