.page-content {
  width: 1100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.post-date {
  display: block;
  margin-bottom: 1rem;
  color: $brand-color;
  font-size:1rem;
}
@media (max-width: $container-width) {
  .page-content {
    width: 60vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media (max-width: $tablet-width) {
  .page-content {
    width: 95vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.post-share {
  padding-bottom: 1rem;
}
.sharing-icons a {
  padding-right: 10px;
}

/*
  Related Posts
*/

.related {
  padding-bottom: 1rem;
}
.related ul, ol, dl {
  margin: 0px;
}
.related-posts {
  padding: 10px 0px 10px;
  list-style: none;
  display: inline-block;
}
.related-thumbnail {
  position: relative;
  width: 600px;
  height: 200px;
  overflow: hidden;
}
.related img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 600px;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}
.related-title {
  float: right;
  padding-bottom: .5rem;
  font-family: $base-font-family;
}
@media (max-width: $container-width) {
  .related-thumbnail{
    width: 60vw;
  }
}
@media (max-width: $tablet-width) {
  .related-thumbnail{
    width: 95vw;
  }
}
