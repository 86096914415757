/*
  Pagination
*/

.pagination {
  text-align: center;
}
.pagination a {
  text-decoration: none;
}
.pagination-button {
  color: #999;
  border: 1px solid #e0e0e0;
  padding: 15px 45px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}
.pagination-active {
  color: black;
}
.pagination-active:hover {
  background-color: #e0e0e0;
}
